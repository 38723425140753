hr.divider {
  border: 1px solid #666;
}

.entry-image {
  display: block;
}

.entry-image img {
  display: block;
  width: 70%;
  height: auto;
  margin: 0 auto;
  border: 1px solid #666;
  border-radius: 4px;
}
@media (min-width: 960px) {
  .entry-image img {
    width: 100%;
  }
}

.entry-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid #121212;
  background: #121212;
  padding: 5px;
  box-sizing: content-box;
  border-radius: 50%;
  vertical-align: bottom;
}

.entry-title {
  vertical-align: middle;
}
.entry-desc {
  margin-top: 1rem;
  font-style: italic;
}

.entry-info-list {
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
}
.entry-info-list li {
  display: inline-block;
}
.entry-info-list li:after {
  content: '|';
  display: inline-block;
  padding: 0 7px;
  color: #666;
}
.entry-info-list li:last-child:after {
  content: '';
  padding: 0;
}