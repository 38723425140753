#main-nav > a {
  margin-right: 20px;
  color: #fff;
  text-transform: uppercase;
}
#main-nav > a.active {
  color: #ed1e1c;
}
#mobile-nav ul li a {
  color: #fff;
  text-transform: uppercase;
}
#mobile-nav ul li a.active {
  color: #ed1e1c;
}
#mobile-nav-button {
  padding: 6px 12px;
  border-radius: 0;
  border: 1px solid #666;
}